import React, { Component } from 'react';
import {
	withStyles,
	Container,
	Box,
	Grid,
	Paper,
	CircularProgress,
	Button,
	Hidden,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
	COLORS,
	HIDE_CRED_FOR_STATUS_INITIAL,
	HIDE_CRED_FOR_STATUS_COMMON,
	DOCUMENT_TYPES,
} from '../../utils/Application_Constants';
import ActiveAssignmentDashboard from '../../components/ContentComponents/ActiveAssignmentDashboard/ActiveAssignmentDashboard';
import UpcomingAssignmentDashboard from '../../components/ContentComponents/UpcomingAssignmentDashboard/UpcomingAssignmentDashboard';
import { getAssignments } from '../../services/AssignmentsService';
import { getPresents } from '../../services/PresentsService';
import PresentsWidgetComponent from '../../components/ContentComponents/PresentsWidgetComponent/PresentsWidgetComponent';
import WithContexts from '../../contexts/withContexts';
import QuickSearchWidget from '../../components/ContentComponents/QuickSearchWidget/QuickSearchWidget';
import msSalService from '../../services/msSalService';
import { getNotificationsByReceiptId } from '../../services/NotificationsService';
import NotificationPopUpForW9DD from '../../components/ContentComponents/Notifications/NotificationPopUpForW9DD';
import AlertNotificationForFirstTimeUsers from '../../components/ContentComponents/Notifications/AlertNotificationForFirstTimeUsers';
import UserContext from '../../contexts/userContext';
import {
	updateUserFirstLoginIn,
	getRecommendedJobList,
	getClinicianAccountV2
} from '../../services/ProfileServices/profileService';
import { getAdobeSignDocumentUrl } from '../../services/DocumentService';
import AssignmentViewAndSignDocumentModel from '../../components/ContentComponents/AssignmentDocumentComponent/AssignmentViewAndSignDocumentModel';
import ErrorDialog from '../../components/GlobalComponents/Dialog/ErrorDialog';
import { withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import CredentialingStatusCard from '../../components/ContentComponents/Credentials/PageComponenets/OverviewComponents/CredentialingStatusCard';
import { getCredAppStatus } from '../../services/Credentialing';
import CarouselComponent from '../../components/GlobalComponents/CarouselComponent/CarouselComponent';
import SalarySurveyTile from '../../components/ContentComponents/LocumTennensResources/SalarySurveyTile';
import ResourcesTile from '../../components/ContentComponents/LocumTennensResources/ResourcesTile';
import PreferenceCompletion from '../../components/ContentComponents/Preferences/PreferenceCompletion';
import { getSamlResponse } from '../../services/peoplenetSsoService';
import businessIcon from '../../assets/icons/Icon_awesome-business-time.svg';
import FeatureFlag from '../../components/GlobalComponents/FeatureFlagComponent/FeatureFlag';

const styles = (theme) => ({
	root: {
		backgroundColor: COLORS.BLACK_HAZE,
		paddingBottom: '30px',
		minHeight: '800px',
	},
	ContainerRoot: {
		paddingTop: '4rem',
	},
	activeAssignmentRoot: {
		//marginTop: '30px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	submitTimesheetBtn: {
		[theme.breakpoints.down('sm')]: {
			marginBottom: '2rem',
		},
	},
	upcomingAssignmentRoot: {
		marginTop: '30px',
		paddingTop: '30px',
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	progressWrapper: {
		height: '80vh',
	},
	progressRoot: {
		marginTop: '25vh',
	},
	helpPanelContainerRootRight: {
		//marginTop: '30px',
		display: 'flex',
		flexWrap: 'wrap',
		paddingBottom: '30px',
		[theme.breakpoints.down('sm')]: {
			padding: '0px',
		},
	},
	tileHeader: {
		color: COLORS.LT_STEEL,
		fontWeight: '700',
		textTransform: 'uppercase',
		letterSpacing: '.5px',
		fontSize: '1rem',
		display: 'block',
		marginBottom: '.5rem',
	},
	presentGrid: {
		marginTop: '-15px',
	},
	preferecneCenterPaper: {
		padding: '1.5rem',
	},
	tileHeaderPreferecne: {
		color: '#6D707E',
		fontWeight: '700',
		letterSpacing: '.5px',
		fontSize: '18px',
		display: 'block',
		marginBottom: '2rem',
		textAlign: 'left',
	},
	timeAndPayHeader: {
		fontWeight: '700',
		letterSpacing: '.5px',
		display: 'block',
		marginBottom: '2rem',
		color: COLORS.LT_STEEL,
		fontSize: '16px',
		textAlign: 'left',
	},
	buttonGridRoot: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		marginTop: '32px',
		fontWeight: '700',
		fontSize: '18px',
		borderLeft: `1px solid ${COLORS.WHITE_LILAC}`,
		[theme.breakpoints.up('md')]: {
			borderBottom: `1px solid ${COLORS.WHITE_LILAC}`,
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			borderTop: `1px solid ${COLORS.WHITE_LILAC}`,
			paddingBottom: '10px',
		},
	},
	buttonRoot: {
		// borderRadius: '0px',
		// textTransform: 'none',
		// boxShadow:'none',
		// fontWeight:'700',
		// fontSize:'18px',
		// marginTop: '1.5rem',
		width: '100%',
	},
	businessIconStyle: {
		margin: '0.5rem',
	},
	errorIcon: {
		width: '14px',
		marginRight: '5px',
	},
	alertWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '14px',
		marginTop: '5px',
		width: '80%',
		paddingBottom: '40px',
		color: COLORS.LT_P,
		[theme.breakpoints.down('sm')]: {
			paddingBottom: '15px',
		},
	},
	timeButtonPaper: {
		marginTop: '-20px',
		marginBottom: '10px',
	},
});
class DashboardL2 extends Component {
	constructor(props) {
		super(props);
		const { setPageName } = this.props.PageContext;
		setPageName('');
		this.state = {
			isLoading: true,
			viewToggleNotification: false,
			openDialog: true,
			openAlert: true,
			signDocument: false,
			signDocumentURL: '',
			signDocumentError: '',
			timesheetNotification: false,
			documentNotification: false,
			recommendedJobList: {},
			credAppStatus: '',
			optimizeVariant: 0,
			hasLTOEAssignments: false,
			flags: {
				isLoading: false,
				dataSaved: false,
				isDirty: false,
				showSaveChanges: false,
				isPendingSave: false,
				targetTab: 0,
				validationError: false,
				navDisabled: false,
				// apiError: '',
				// errorMessage: ''
			},
		};
	}

	async componentDidMount() {
		this.mountDashboard();
		msSalService.setInitialRedirectUri();

		this.setRecEngineMethod(true);
	}

	async setRecEngineMethod(useAltSearch) {
		const { externalId } = this.props.UserContext;
		await Promise.all([getRecommendedJobList(externalId, useAltSearch)])
			.then(([recommendedJobList]) => {
				console.log('gotJobs:' + recommendedJobList);
				if (recommendedJobList && recommendedJobList.length > 4) {
					recommendedJobList = recommendedJobList.slice(0, 4);
				}
				this.setState({
					recommendedJobList: recommendedJobList,
				});
			})
			.catch((e) => {
				this.setDashboardPageError(e);
			});
	}

	setDashboardPageError = (e) => {
		const { flags } = { ...this.state };
		if (e.hasOwnProperty('title') && e.hasOwnProperty('message')) {
			flags.apiError = e;
		} else {
			flags.errorMessage = 'Error loading page';
		}
		flags.isLoading = false;
		flags.isPendingSave = false;
		this.setState({ flags: flags });
		this.setState({ isLoading: false });
	};

	closeError = () => {
		const flags = { ...this.state };
		flags.apiError = null;
		this.setState({ flags: flags });
	};

	setIsLoaded = (value) => {
		this.setState({ isLoading: !value });
	};

	async mountDashboard() {
		const { externalId } = this.props.UserContext;
		await Promise.all([
			getAssignments(externalId, 'active'),
			getPresents(externalId),
			getAssignments(externalId, 'upcoming'),
			getNotificationsByReceiptId(externalId),
			getCredAppStatus(externalId),
			getClinicianAccountV2(externalId)
		])
			.then(([active, presents, upcoming, notifications, credStatus, clinicianInfo]) => {
				var hasLTOEAssignments = clinicianInfo?.hasLTOEAssignments ?? false;
				this.setState({
					activeAssignmentList: active,
					upcomingAssignmentList: this.filterUpcomingAssignments(upcoming),
					presents: presents,
					notifications: notifications,
					isLoading: false,
					credAppStatus: credStatus,
					hasLTOEAssignments: hasLTOEAssignments
				});
			})
			.catch((e) => {
				this.setDashboardPageError(e);
			});

		let documentFlag = false;
		for (let notification in this.state.notifications) {
			if (
				this.state.notifications[notification].payload &&
				(JSON.parse(this.state.notifications[notification].payload).docType ===
					DOCUMENT_TYPES.DD ||
					JSON.parse(this.state.notifications[notification].payload).docType ===
						DOCUMENT_TYPES.W9)
			) {
				documentFlag = true;
			}
		}
		this.setState({ documentNotification: documentFlag });
	}

	filterUpcomingAssignments(assignments) {
		if (!assignments) {
			return null;
		}
		const filteredAssignments = [];
		for (const assignment of assignments) {
			if (
				assignment.location.addressLine1 ||
				(assignment.location.city && assignment.location.state)
			) {
				filteredAssignments.push(assignment);
			}
		}
		return filteredAssignments;
	}

	toggleNotifications = (isViewable) => {
		this.setState({ openDialog: isViewable });
		localStorage.setItem('LocalStorage_UserSignedIN', 'true');
	};

	displayCredStatusTile = () => {
		const { credAppStatus } = this.state;
		if (
			credAppStatus?.credentialingType === 'Initial' &&
			HIDE_CRED_FOR_STATUS_INITIAL.indexOf(credAppStatus?.status) >= 0 &&
			(credAppStatus?.priorStatus === null || credAppStatus?.priorStatus === undefined)
		) {
			return false;
		}

		if (
			credAppStatus?.credentialingType === 'Re-cred' &&
			HIDE_CRED_FOR_STATUS_COMMON.indexOf(credAppStatus?.status) >= 0 &&
			(credAppStatus?.priorStatus === null || credAppStatus?.priorStatus === undefined)
		) {
			return false;
		}

		return true;
	};
	toggleSignInAlert = async (isViewable, alertChecked) => {
		if (alertChecked) {
			if (this.context.account.idToken.emails) {
				await updateUserFirstLoginIn(this.context.account.idToken.emails[0])
					.then((response) => {
						console.log('Update FirstLogin User Flag Response: ' + response);
					})
					.catch((e) => {
						this.setDashboardPageError(e);
					});
			}
		}

		localStorage.setItem('LocalStorage_FirstTimeUserLogin', 'true');
		this.setState({ openAlert: isViewable });
	};

	signDocumentHandler = async (notification) => {
		this.setState({
			signDocument: !this.state.signDocument,
			signDocumentError: '',
			signDocumentURL: '',
		});
		const data = JSON.parse(notification.payload);

		if (data.clinicianExternalId && data.agreementId && data.senderEmail) {
			let adobeSignUrl = await getAdobeSignDocumentUrl(
				data.clinicianExternalId,
				data.agreementId,
				data.senderEmail
			).catch((e) => {
				this.setDashboardPageError(e);
			});
			if (adobeSignUrl) {
				this.setState({ signDocumentURL: adobeSignUrl });
			} else {
				this.setState({ signDocumentError: 'error' });
			}
		} else {
			this.setState({ signDocumentError: 'error' });
		}
	};

	toggleSignDocumentModal = async (isViewable) => {
		this.setState({ signDocument: isViewable });
	};

	renderAlertNotificationForFirstTimeSignIn = () => {
		const { isFirstLogin } = this.context;

		if (
			isFirstLogin === undefined &&
			localStorage.getItem('LocalStorage_FirstTimeUserLogin') === null
		) {
			return (
				<AlertNotificationForFirstTimeUsers
					toggleHandler={this.toggleSignInAlert}
					openDialog={this.state.openAlert}
				/>
			);
		}
		return null;
	};

	renderPopUpW9DDNotification = () => {
		const { notifications, documentNotification } = this.state;
		if (
			notifications != null &&
			notifications.length > 0 &&
			localStorage.getItem('LocalStorage_UserSignedIN') === null &&
			documentNotification
		) {
			return (
				<NotificationPopUpForW9DD
					toggleHandler={this.toggleNotifications}
					openDialog={this.state.openDialog}
					notifications={notifications}
					signDocumentHandler={this.signDocumentHandler}
					documentNotification={documentNotification}
				/>
			);
		}
		return null;
	};

	adobeSignDocumentModal() {
		const { signDocument, signDocumentURL, signDocumentError } = this.state;
		if (signDocument === true) {
			return (
				<AssignmentViewAndSignDocumentModel
					credentialingSigningUrl={signDocumentURL}
					toggleHandler={this.toggleSignDocumentModal}
					openDialog={signDocument}
					responseTimeout={signDocumentError}
				/>
			);
		}

		return null;
	}

	handleAppcuesTrack = () => {
		window.Appcues.track("Enter Timesheet Flow on Landing Page (Submit)", {
			key: "Click the submit timesheet button on landing page",
			description: "Enter the timesheet landing page in LTOE"
		})
	}

	renderTimesheetButton() {
		const { classes, t } = this.props;
		const { hasLTOEAssignments } = this.state;
		return (
			<div align='center' className={classes.submitTimesheetBtn}>
				{hasLTOEAssignments ?
					<Link to='/timesheets'>
						<Button
							variant='contained'
							color='primary'
							classes={{ root: classes.buttonRoot }}
							onClick={this.handleAppcuesTrack}
						>
							<img
								src={businessIcon}
								alt={t(`dashboard:BUTTONS.SUBMIT_TS`)}
								className={classes.businessIconStyle}
							></img>
							{t(`dashboard:BUTTONS.SUBMIT_TS`)}
						</Button>
					</Link>
					:
					<Button
						variant="contained"
						color="primary"
						classes={{ root: classes.buttonRoot }}
						onClick={getSamlResponse}
					>
						<img src={businessIcon} alt={t(`dashboard:BUTTONS.SUBMIT_TS`)} className={classes.businessIconStyle}></img>
						{t(`dashboard:BUTTONS.SUBMIT_TS`)}
					</Button>
				}
				<div>
					{/* <Error classes={{ root: classes.errorIcon }} /> */}
					<p className='cr-pt-1 cr-pb-0 cr-pepper cr-text-sm cr-text-center cr-text-bold cr-mb-0'>
						{t('error:TIMESHEET_ALERTS.TIMESHEET_DUE_MONDAY')}
					</p>
				</div>
			</div>
		);
	}

	render() {
		const {
			activeAssignmentList,
			upcomingAssignmentList,
			isLoading,
			presents,
			flags,
			optimizeVariant,
			recommendedJobList,
		} = this.state;
		console.log(' recommendedJobList  -> ' + JSON.stringify(recommendedJobList));
		const { classes, t } = this.props;
		const { externalId } = this.props.UserContext;
		if (isLoading) {
			return (
				<div className={classes.progressWrapper}>
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				</div>
			);
		}
		return (
			<>
				<div className={classes.root}>
					<ErrorDialog
						open={!!flags.apiError}
						onClose={this.closeError}
						title={!flags.apiError ? null : flags.apiError.title}
						description={!flags.apiError ? null : flags.apiError.message}
						errorId={!flags.apiError ? null : flags.apiError.errorId}
					/>
					<Container
						maxWidth='xl'
						className={classes.ContainerRoot}
						justify='space-evenly'
					>
						{/* <Grid item xs={12}> */}
						<Grid container justifyContent='space-around' spacing={4}>
							<Grid item xs={12} md={8}>
								<Hidden mdUp>{this.renderTimesheetButton()}</Hidden>

								<Box mb={4}>
									<ActiveAssignmentDashboard assignments={activeAssignmentList} />
								</Box>
								<UpcomingAssignmentDashboard assignments={upcomingAssignmentList} />

								<Grid container spacing={4}>
									<Grid item xs={12} md={6} style={{ marginTop: '2rem' }}>
										<span className='tileHeader'>
											{t(`dashboard:HEADERS.PREF_CENTER`)}
										</span>
										<Paper className={classes.preferecneCenterPaper}>
											<PreferenceCompletion externalId={externalId} />
										</Paper>
									</Grid>

									{this.displayCredStatusTile() ? (
										<Grid item xs={12} md={6} style={{ marginTop: '2rem' }}>
											<span className='tileHeader'>
												{t(`dashboard:HEADERS.YOUR_CREDENTIALS`)}
											</span>
											<CredentialingStatusCard
												t={t}
												setCredAppError={this.setDashboardPageError}
												UserContext={this.props.UserContext}
												isLoaded={isLoading}
												setIsLoaded={this.setIsLoaded}
											/>
										</Grid>
									) : null}
								</Grid>
							</Grid>

							<Grid item xs={12} md={4}>
								<Grid container className={classes.helpPanelContainerRootRight}>
									<Grid item xs={12}>
										<Hidden smDown>{this.renderTimesheetButton()}</Hidden>
									</Grid>

									<Grid item xs={12} style={{ marginTop: '2rem' }}>
										<PresentsWidgetComponent presents={presents} />
									</Grid>
									{/*<AdCard />*/}
								</Grid>
							</Grid>
						</Grid>
						<Grid container justify='space-around' spacing={4}>
							{recommendedJobList.length > 0 ? (
								<Grid item xs={12} md={12}>
									<span className={classes.tileHeader}>
										{t(`dashboard:HEADERS.RECOMMENDED_JOBS`)}
									</span>
									<CarouselComponent
										recommendedJobList={recommendedJobList}
										optimizeVariant={optimizeVariant}
										t={t}
									/>
								</Grid>
							) : null}
						</Grid>

						<Grid container style={{ marginTop: '2rem' }}>
							<Grid item xs={12}>
								<span className='tileHeader'>
									{t(`dashboard:HEADERS.SELF_EMP_HEADER`)}
								</span>
							</Grid>
						</Grid>
						<Grid container spacing={4}>
							<Grid item xs={12} md={4}>
								<ResourcesTile t={t} />
							</Grid>

							<Grid item xs={12} md={4}>
								<SalarySurveyTile t={t} />
							</Grid>

							<Grid item xs={12} md={4}>
								<QuickSearchWidget title={'Quick Job Search'} level1={false} />
							</Grid>
						</Grid>
						<FeatureFlag name='ShowPopUpW9DDNotificaiton' isFlagEnabled='true'>
							{this.renderPopUpW9DDNotification()}
						</FeatureFlag>
						{this.renderAlertNotificationForFirstTimeSignIn()}
						{this.adobeSignDocumentModal()}
					</Container>
				</div>
			</>
		);
	}
}

DashboardL2.propTypes = {
	classes: PropTypes.shape({
		root: PropTypes.string.isRequired,
	}).isRequired,
};

DashboardL2.contextType = UserContext;
export default WithContexts(withRouter(withTranslation()(withStyles(styles)(DashboardL2))));
