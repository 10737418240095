/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
	IconButton,
	makeStyles,
	Grid,
	Hidden,
	TextField,
	MenuItem,
	Menu,
	Typography,
	InputLabel,
	Divider,
	Button,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import WithContexts from '../../../contexts/withContexts';
import { MoreVert, DeleteOutline, MoreHoriz } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { AdminAuthContext } from '../../../Admin/Contexts/AdminAuthContext';
import moment from 'moment';
import TimesheetBreakStylesV1 from './TimesheetBreakStylesV1.js';
import TimesheetBreakStylesV2 from './TimesheetBreakStylesV2.js';

function TimesheetBreak(props) {
	const { entryIndex, index, timesheetBreak, isPaidApproved, calculateEntryHours, breakRemove, isReadOnly, isLastBreakInEntry, isMobile, isMobileUiV2Enabled } =
		props;

	const getStylesheet = () => {
		const styles = isMobileUiV2Enabled
			? TimesheetBreakStylesV2
			: TimesheetBreakStylesV1;

		const useStyles = makeStyles(styles);
		return useStyles();
	};

	const classes = getStylesheet();

	const { errors, clearErrors, setValue, getValues } = useFormContext();
	const [breakHours, setBreakHours] = useState(0);
	const [startTime, setStartTime] = useState(timesheetBreak.startTime);
	const [endTime, setEndTime] = useState(timesheetBreak.endTime);

	const adminAuth = useContext(AdminAuthContext);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		calculateEntryHours();
	}, [breakHours, timesheetBreak]);

	const calculateBreakHours = (startTime, endTime) => {
		if (!startTime || !endTime) {
			setValue(`timesheetEntries[${entryIndex}].timesheetBreaks[${index}].totalTimeInHours`, 0);
			setBreakHours(0);
		}
		const formattedStartTime = moment(startTime, 'HH:mm');
		const formattedEndTime = moment(endTime, 'HH:mm');

		let hours = moment.duration(formattedEndTime.diff(formattedStartTime)).asHours();
		if (hours <= 0)
			hours += 24;

		const formattedHours = Number(hours.toFixed(2));
		setValue(`timesheetEntries[${entryIndex}].timesheetBreaks[${index}].totalTimeInHours`, formattedHours);
		setBreakHours(formattedHours);
	};

	return (
		<>
			<Hidden
				only={
					isPaidApproved || adminAuth?.isLTAssociateUser
						? ['xs', 'sm', 'md', 'lg', 'xl']
						: ['md', 'lg', 'xl']
				}
			>
				<div className={classes.mobileMenuButton}>
					<IconButton
						id={`timesheet-break--${entryIndex}-${index}--menu`}
						color='default'
						aria-label='More options'
						onClick={handleClick}
						disabled={isReadOnly ? true : false}
					>
						<MoreHoriz />
					</IconButton>
					<Menu
						id='timesheetBreak-menu'
						anchorElFre={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem
							onClick={() => {
								breakRemove(index);
								handleClose();
							}}
							id={`timesheet-break--${entryIndex}-${index}--menu--list-item--remove`}
						>
							<Grid container alignItems='center' spacing={2}>
								<Grid item className={classes.menuIcon}>
									<DeleteOutline
										fontSize='small'
										className={classes.menuIconRemove}
									/>
								</Grid>
								<Grid item>
									<Typography variant='body2'>Remove</Typography>
								</Grid>
							</Grid>
						</MenuItem>
					</Menu>
				</div>
			</Hidden>
			<Grid container className={classes.breakRow} id={`timesheet-break--${entryIndex}-${index}`}>
				<Grid className={classes.breakMobileHeader} item xs={12}>
					<Divider className={classes.breakDivider} />
					<h4 className={classes.breakMobileHeaderText}>Break</h4>
				</Grid>
				<Controller
					name={`timesheetEntries[${entryIndex}].timesheetBreaks[${index}].id`}
					defaultValue={timesheetBreak.id}
				/>
				<Controller
					name={`timesheetEntries[${entryIndex}].timesheetBreaks[${index}].timesheetEntryId`}
					defaultValue={timesheetBreak.timesheetEntryId}
				/>
				<Grid item xs={isMobileUiV2Enabled ? 12 : 4} md={2} lg={1}></Grid>
				<Grid item xs={isMobileUiV2Enabled ? 12 : 4} md={2} lg={3}></Grid>
				<Grid className={`${classes.firstBreakCell} ${classes.entryCell}`} item xs={isMobileUiV2Enabled ? 12 : 4} md={2} lg={1}>
					<TextField 
						className={`${classes.formControl} timesheet-break--cell`}
						value={'Break'}
						disabled={true}
						InputProps={{
							classes: {
								input: classes.input,
								root: classes.inputRoot,
								disabled: classes.disabled,
								notchedOutline: classes.notchedOutline,
							},
						}}
						variant='outlined'
					/>
				</Grid>
				<Grid className={classes.unitsGrid} xs={4} md={1}>
					<Controller
						name={`timesheetEntries[${entryIndex}].timesheetBreaks[${index}].units`}
						defaultValue={timesheetBreak.units ?? ""}
						render={({ value, ref }) => (
							<TextField
								id={`timesheet-break--${entryIndex}-${index}--units`}
								className={`${classes.formControl} timesheet-break--cell`}
								value={value}
								inputRef={ref}
								disabled={ true }
								InputProps={{
									classes: {
										input: classes.input,
										root: classes.inputRoot,
										disabled: classes.disabled,
										notchedOutline: classes.notchedOutline,
									},
								}}
								variant='outlined'
							/>
						)}
					/></Grid>
				<Grid className={classes.entryCell} item xs={isMobileUiV2Enabled ? 6 : 4} md={1}>
					<InputLabel shrink classes={{ root: classes.inputLabel }}>
						Start
					</InputLabel>
					<Controller
						name={`timesheetEntries[${entryIndex}].timesheetBreaks[${index}].startTime`}
						defaultValue={timesheetBreak.startTime}
						rules={{
							required: { value: endTime, message: 'Start time is required' }
						}}
						render={({ onChange, value, ref }) => (
							<TextField
								id={`timesheet-break--${entryIndex}-${index}--start-time`}
								type='time'
								className={`${classes.formControl} timesheet-break--cell`}
								value={value}
								disabled={
									isReadOnly
								}
								onChange={(e) => {
									setStartTime(e.target.value);
									calculateBreakHours(e.target.value, endTime);
									clearErrors(`timesheetEntries[${entryIndex}].timesheetBreaks[${index}].startTime`);
									onChange(e);
								}}
								onKeyDown={(e) => {
									if (e.code === 'Space') e.preventDefault()
								}}
								inputRef={ref}
								InputProps={{
									classes: {
										input: classes.input,
										root: classes.inputRoot,
										disabled: classes.disabled,
										notchedOutline: classes.notchedOutline,
									},
								}}
								inputProps={{
									step: 300, // 5 min
								}}
								variant='outlined'
								error={errors['timesheetEntries']?.[entryIndex]?.timesheetBreaks?.[index]?.startTime ? true : false}
								helperText={errors['timesheetEntries']?.[entryIndex]?.timesheetBreaks?.[index]?.startTime?.message}
							/>
						)}
					/>
				</Grid>
				<Grid className={classes.entryCell} item xs={isMobileUiV2Enabled ? 6 : 4} md={1}>
					<InputLabel shrink classes={{ root: classes.inputLabel }}>
						End
					</InputLabel>
					<Controller
						name={`timesheetEntries[${entryIndex}].timesheetBreaks[${index}].endTime`}
						defaultValue={timesheetBreak.endTime}
						rules={{
							required: { value: startTime, message: 'End time is required' },
						}}
						render={({ onChange, value, ref }) => (
							<TextField
								id={`timesheet-break--${entryIndex}-${index}--end-time`}
								type='time'
								className={`${classes.formControl} timesheet-break--cell`}
								value={value}
								inputRef={ref}
								disabled={
									isReadOnly
								}
								onChange={(e) => {
									setEndTime(e.target.value);
									calculateBreakHours(startTime, e.target.value);
									clearErrors(`timesheetEntries[${entryIndex}].timesheetBreaks[${index}].endTime`);
									onChange(e);
								}}
								onKeyDown={(e) => {
									if (e.code === 'Space') e.preventDefault()
								}}
								InputProps={{
									classes: {
										input: classes.input,
										root: classes.inputRoot,
										disabled: classes.disabled,
										notchedOutline: classes.notchedOutline,
									},
								}}
								inputProps={{
									step: 300, // 5 min
								}}
								variant='outlined'
								error={errors['timesheetEntries']?.[entryIndex]?.timesheetBreaks?.[index]?.endTime ? true : false}
								helperText={errors['timesheetEntries']?.[entryIndex]?.timesheetBreaks?.[index]?.endTime?.message}
							/>
						)}
					/>
				</Grid>
				<Controller
					name={`timesheetEntries[${entryIndex}].timesheetBreaks[${index}].totalTimeInHours`}
					defaultValue={timesheetBreak.totalTimeInHours ?? 0}
				/>
				<Grid item xs={1} sm='auto' className={classes.menuGrid}>
					<Hidden
						only={
							isPaidApproved || adminAuth?.isLTAssociateUser
								? ['xs', 'sm', 'md', 'lg', 'xl']
								: ['xs', 'sm']
						}
					>
						<div
							className={`${classes.menuButtonCell} ${isReadOnly ? classes.disabledBackgroundColor : ''
								}`}
						>
							<IconButton
								id={`timesheet-break--${entryIndex}-${index}--menu`}
								className={classes.menuButton}
								color='default'
								aria-label='More options'
								onClick={handleClick}
								disabled={isReadOnly ? true : false}
							>
								<MoreVert />
							</IconButton>
							<Menu
								id='timesheetBreak-menu'
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<MenuItem
									onClick={() => {
										breakRemove(index);
										handleClose();
									}}
									id={`timesheet-break--${entryIndex}-${index}--menu--list-item--remove`}
								>
									<Grid container alignItems='center' spacing={2}>
										<Grid item className={classes.menuIcon}>
											<DeleteOutline
												fontSize='small'
												className={classes.menuIconRemove}
											/>
										</Grid>
										<Grid item>
											<Typography variant='body2'>Remove</Typography>
										</Grid>
									</Grid>
								</MenuItem>
							</Menu>
						</div>
					</Hidden>
				</Grid>
				<Grid item xs={1} className={classes.connectorGrid}>
					<div className={classes.midBreakConnectorContainer}>
						<div className={classes.midBreakConnector}></div>
					</div>	
					{isLastBreakInEntry ? null : <div className={classes.endBreakConnectorContainer}><div className={classes.endBreakConnector}></div></div>}
				</Grid>
				<Grid item xs={12} hidden={!isMobile || !isMobileUiV2Enabled || isPaidApproved || isReadOnly}>
					<Button
						variant='contained'
						classes={{ root: classes.breakDeleteButton }}
						onClick={() => {
							breakRemove(index);
							handleClose();
						}} >
						Delete Break
					</Button>
				</Grid>
			</Grid>
		</>
	);
}

TimesheetBreak.propTypes = {
	timesheetBreak: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		timesheetEntryId: PropTypes.number,
		startTime: PropTypes.string,
		endTime: PropTypes.string,
		totalTimeInHours: PropTypes.number,
	}).isRequired,
};

export default WithContexts(withRouter(withTranslation()(TimesheetBreak)));
